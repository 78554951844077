import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import { BURNER_IS_AUTH, CUSTOM_WEB_EVENT, ERROR_EVENT, ROUTERS } from '../../constants';
import * as actionCreators from '../../ducks/error';
import * as generalActionCreators from '../../ducks/general';
import * as chooseNumberActions from '../../ducks/chooseNumber';
import cookie from 'react-cookie';

const propTypes = {
  clearErrors: PropTypes.func.isRequired,
  clearUserInfo: PropTypes.func.isRequired,
  error: PropTypes.shape({
    isUnauthorized: PropTypes.bool,
    errorMessage: PropTypes.string,
    shouldLog: PropTypes.bool,
    goBack: PropTypes.func,
    redirectBackAction: PropTypes.string,
  }).isRequired,
};

class ErrorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;

    if (prevProps.error.errorMessage === error.errorMessage) {
      return;
    }

    if (error.errorMessage) {
      this.openModal();
    } else if (!error.errorMessage) {
      this.closeModal();
    }
  }

  openModal() {
    this.setState(() => ({
      modalIsOpen: true,
    }));
  }

  closeModal() {
    const { error, clearUserInfo, clearErrors } = this.props;
    const { router } = this.context;

    this.setState(() => ({
      modalIsOpen: false,
    }));

    if (error.isUnauthorized) {
      cookie.remove(BURNER_IS_AUTH);
      clearUserInfo();
    }

    const redirectPath = error.redirectBackAction || (error.isUnauthorized ? ROUTERS.login : null);
    if (redirectPath) {
      router.push(redirectPath);
    }

    clearErrors();
  }

  render() {
    const { modalIsOpen } = this.state;
    const { error } = this.props;

    if (error.shouldLog) {
      dataLayer.push({
        event: CUSTOM_WEB_EVENT,
        customWebEventName: ERROR_EVENT,
        customWebEventLabel: error.errorMessage,
      });
    }

    return (
      <Modal
        contentLabel="Error Modal"
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        shouldCloseOnEsc={!error.isUnauthorized}
        shouldCloseOnOverlayClick={!error.isUnauthorized}
        className="burner-modal burner-modal--error"
        bodyOpenClassName={null}
        overlayClassName="burner-modal-overlay"
        ariaHideApp={false}
      >
        <div id="errorModal">
          <div className="row">
            <div className="large-12 columns">
              <h1 className="Header__title Header__title--error">Whoops!</h1>
              <p>{error.errorMessage}</p>
              <button type="submit" onClick={this.closeModal} className="flat button confirm-vc-button submit">
                {error.isUnauthorized ? 'Log in' : 'Go back'}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ErrorModal.propTypes = propTypes;
ErrorModal.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    error: state.error,
    errorChooseNumber: state.chooseNumberPage.error,
  };
}

const mapDispatchToProps = { ...actionCreators, ...chooseNumberActions, ...generalActionCreators };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorModal);
