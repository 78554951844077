import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { validateAreaCode } from '../../utils/phone';
import Button from '../form/button/Button';

import * as chooseActions from '../../ducks/chooseNumber';
import * as progressBar from '../../ducks/progressBar';
import * as errorActions from '../../ducks/error';

import '!style-loader!css-loader!sass-loader!../../../styles/subscribe-rebrand.scss';
import cookie from 'react-cookie';
import {AUTHENTICATION_KEY, BURNER_IS_AUTH, CURRENT_USER} from '../../constants';

const propTypes = {
  headlineText: PropTypes.string,
  onAreaCodeEnter: PropTypes.func,
  template: PropTypes.string,
};

/**
 * Area code input box for the subscription purchase page (aka. the premium-3 page).
 */
class AreaCodeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaCode: '',
      errorMessage: null,
      submitButtonMobile: 'fixed',
    };

    this.buttonRef = React.createRef();
    this.inputRef = React.createRef();
    this.fieldRef = React.createRef();
  }

  componentDidMount = () => {
    if (this.fieldRef.current && window.innerWidth > 753) {
      this.fieldRef.current.focus();
    }

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate = (prevProps) => {
    const { error } = this.props;

    if (error && error !== prevProps.error) {
      this.handleUpdateError();
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (window.innerWidth < 753) {
      if (!this.inputRef?.current.contains(event.target)) {
        this.handleBlur();
      }

      if (this.buttonRef?.current.contains(event.target)) {
        this.handleSubmit();
      }
    }
  }

  handleFocus = () => {
    if (window.innerWidth < 753) {
      this.setState({
        submitButtonMobile: 'mobile',
      });
    }
  }

  handleBlur = () => {
    if (window.innerWidth < 753) {
      this.setState({
        submitButtonMobile: 'fixed',
      });
    }
  }

  handleUpdateError = () => {
    const { error } = this.props;

    this.setState({
      errorMessage: error,
    });
  }

  handlePhoneChange = (values) => {
    const { clearErrorNumber, clearErrors } = this.props;
    clearErrorNumber();
    clearErrors();

    this.setState(() => ({
      areaCode: values.value,
      errorMessage: null,
    }));
  }

  handleSelectAreaCode = (areaCode) => () => {
    const { onAreaCodeEnter } = this.props;
    const isAuth = cookie.load(BURNER_IS_AUTH);

    if (!isAuth) {
      cookie.remove(AUTHENTICATION_KEY);
      cookie.remove(CURRENT_USER);
    }
    onAreaCodeEnter(areaCode);
  }

  handleSubmit = (event) => {
    event?.preventDefault();

    const { areaCode } = this.state;
    const { onAreaCodeEnter } = this.props;

    const errorMessage = validateAreaCode(areaCode);

    if (errorMessage) {
      this.setState(() => ({
        errorMessage,
      }));
      return;
    }

    localStorage.clear();
    sessionStorage.clear();
    const isAuth = cookie.load(BURNER_IS_AUTH);

    if (!isAuth) {
      cookie.remove(AUTHENTICATION_KEY);
      cookie.remove(CURRENT_USER);
    }

    onAreaCodeEnter(areaCode);
  }

  areaCodePickerField = () => {
    const { areaCode } = this.state;

    return (
      <div
        ref={this.inputRef}
      >
        <label htmlFor="AreaCode" className="Form__fieldWrapper Form__fieldArea text-left cell">Area code</label>
        <NumberFormat
          getInputRef={this.fieldRef}
          id="AreaCode"
          name="AreaCode"
          format="###"
          type="tel"
          className="area-code Input__field text-center medium"
          onValueChange={this.handlePhoneChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          value={areaCode}
          placeholder="XXX"
        />
      </div>
    );
  }

  handleShowPopularCodes = () => {
    const listOfPopularArea = ['917', '647', '305'];

    return (
      <div className="CodeInput__popular--container">
        <h3 className="CodeInput__popular--title">Popular choices:</h3>
        <ul className="CodeInput__popular">
          {listOfPopularArea.map((area) => (
            <li className="CodeInput__popular--code" key={area}>
              <button
                className="CodeInput__popular--button"
                type="button"
                onClick={this.handleSelectAreaCode(area)}
              >
                {area}
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  renderPicker = () => {
    const { error, authError } = this.props;
    const { errorMessage } = this.state;

    const errorToShow = errorMessage || error || (authError.isUnauthorized && authError.errorMessage);

    return (
      <div className="CodeInput__container cell medium-5 small-12 position-fixed">
        <form onSubmit={this.handleSubmit} className="CodeInput__form">
          {errorToShow && (
            <div
              className="CodeInput__error text-left medium-text-center Form__outputWrapper grid-x text-error align-center">
              <div className="cell large-12">
                <div className="error error-msg md:center">{errorToShow}</div>
              </div>
            </div>
          )}

          <div className="Form__inputWrapper--area u-margin-x-auto grid-y grid-padding-x align-center">
            {this.areaCodePickerField()}
            {this.handleShowPopularCodes()}

            <div
              ref={this.buttonRef}
              className={
                classNames('Form__submitWrapper Form__fieldArea--submit ',
                  `FieldArea__submit--${this.state.submitButtonMobile}`
                )
              }
            >
              <Button text="Continue" type="submit" withArrow className="AreaCode__cta" />
            </div>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="CodeInput__wrapper grid-x align-center u-width-full">
        {this.renderPicker()}
      </div>
    )
  }
}

AreaCodeInput.propTypes = propTypes;
AreaCodeInput.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authError: state.error,
    error: state.chooseNumberPage.error,
  };
}

const mapDispatchToProps = { ...chooseActions, ...progressBar, ...errorActions };

export default connect(mapStateToProps, mapDispatchToProps)(AreaCodeInput);
