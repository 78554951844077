import React, { Component } from 'react';
import { connect } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { reset } from 'redux-form';
import PropTypes from 'prop-types';

import * as chooseActions from '../ducks/chooseNumber';
import * as progressBar from '../ducks/progressBar';
import * as subscribeActions from '../ducks/subscribe';
import * as errorActions from '../ducks/error';
import * as verifyActions from '../ducks/verify';

import PhoneNumberContent from '../components/create-account/PhoneNumberContent';
import VerifyContent from '../components/create-account/VerifyContent';
import { defaultCountryObject, VERIFY_SENT} from '../constants';
import { getStorageData, getStoragePhoneData, removeStorageData, setStorageVerifyPage } from '../utils/sessionStorage';

import '!style-loader!css-loader!sass-loader!../../styles/phone-number.scss';

class PhoneNumberAuthContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: defaultCountryObject,
      isShowVerify: getStorageData(VERIFY_SENT),
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerifySubmit = this.handleVerifySubmit.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleReturnToNumber = this.handleReturnToNumber.bind(this);
    this.handleClearServerError = this.handleClearServerError.bind(this);

    this.phone = getStoragePhoneData();
  }

  componentDidMount() {
    if (!getStorageData(VERIFY_SENT) && this.state.isShowVerify) {
      this.handleReturnToNumber();
    }
  }

  componentDidUpdate() {
    const { codeWasSent } = this.props;
    const isShowNumberContent = (codeWasSent && !this.state.isShowVerify);

    if (isShowNumberContent) {
      this.handleReturnToNumber();
    }
  }

  componentWillUnmount() {
    removeStorageData(VERIFY_SENT);
  }

  handleReturnToNumber() {
    this.setState(prev => ({
      isShowVerify: !prev.isShowVerify,
    }));
  }

  handleBack() {
    const { resetForm, clearCodeState, clearErrors } = this.props;
    resetForm('subscribe');
    clearCodeState();
    clearErrors();

    this.setState({
      selectedCountry: defaultCountryObject,
    })

    this.handleReturnToNumber();
  }

  handleVerifySubmit(verifyCode) {
    const { handleSubmitVerify, formState } = this.props;

    handleSubmitVerify(
      formState?.values?.phoneNumber || this.phone?.number,
      verifyCode,
    );
  }

  handleCountryChange(newCountry) {
    this.setState({ selectedCountry: newCountry });
  }

  handleSubmit() {
    const { handleSubmitNumber, formState: { values }, verifyErrorMessage } = this.props;
    const { phoneNumber, selectedCountry } = values;

    const phone = parsePhoneNumberFromString(
      phoneNumber,
      selectedCountry.countryCode
    );

    if (!verifyErrorMessage) {
      handleSubmitNumber(phone.number);
    }

    setStorageVerifyPage(true);
  }

  handleClearServerError() {
    const { clearErrors, resetVerifyCodeError } = this.props;

    resetVerifyCodeError();
    clearErrors();
  }

  render() {
    const { selectedCountry, isShowVerify } = this.state;
    const { toggleResendModal, formState, error, verifyErrorMessage, isShowBar, template } = this.props;

    if (isShowVerify) {
      return (
        <VerifyContent
          template={template}
          isShowBar={isShowBar}
          error={verifyErrorMessage || error?.errorMessage}
          phone={formState?.values?.phoneNumber || this.phone?.number}
          onResend={toggleResendModal}
          handleSubmit={this.handleVerifySubmit}
          handleBack={this.handleBack}
        />
      );
    }

    return (
      <PhoneNumberContent
        template={template}
        isShowBar={isShowBar}
        serverError={error?.errorMessage}
        selectedCountry={selectedCountry}
        submitHandler={this.handleSubmit}
        handleCountryChange={this.handleCountryChange}
        handleClearServerError={this.handleClearServerError}
      />
    );
  }
}

PhoneNumberAuthContainer.propTypes = {
  isShowBar: PropTypes.bool,
  error: PropTypes.shape({
    isUnauthorized: PropTypes.bool,
    errorMessage: PropTypes.string,
    shouldLog: PropTypes.bool,
    goBack: PropTypes.func,
    redirectBackAction: PropTypes.func,
  }).isRequired,
  codeWasSent: PropTypes.bool.isRequired,
  verifyErrorMessage: PropTypes.bool,
  isResend: PropTypes.bool.isRequired,
  formState: PropTypes.object.isRequired,
  template: PropTypes.string,
}

PhoneNumberAuthContainer.defaultProps = {
  isShowBar: true,
  codeWasSent: false,
  isResend: false,
  formState: {
    values: {
      phoneNumber: null,
      selectedCountry: '',
    },
  },
  template: '',
}

PhoneNumberAuthContainer.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    formState: state.form.subscribe,
    error: state.error,
    currentStep: state.progressBar.currentStep,
    codeWasSent: state.verifyPage.codeWasSent,
    verifyErrorMessage: state.verifyPage.verifyErrorMessage,
    isSubscribeUser: state.subscribePage.isSubscribeUser,
    selectedNumber: state.subscribePage.selectedNumber,
  };
}

const mapDispatchToProps = {
  ...verifyActions,
  ...subscribeActions,
  ...errorActions,
  ...chooseActions,
  ...progressBar,
  resetForm: reset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(PhoneNumberAuthContainer));
