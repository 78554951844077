import React, { Component } from 'react';
import OtpInput from 'react-otp-input';
import { connect } from 'react-redux';

import Container from '../common/Container';
import Button from '../form/button/Button';
import { BackwardArrow } from '../icons/backwardArrow';
import { renderLocalNumber } from '../../utils/phone';
import { trackAmplitudeFailedEvent } from '../../utils/analytics';
import { removeStorageData } from '../../utils/sessionStorage';
import { VERIFY_SENT } from '../../constants';

import '!style-loader!css-loader!sass-loader!../../../styles/verify-content.scss';

const ForwardedOtpInput = React.forwardRef((props, ref) => (
  <OtpInput {...props} inputRef={ref} />
));

class VerifyContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: null,
      submitButtonMobile: 'fixed',
      otpError: null,
    }

    this.buttonRef = React.createRef();
    this.inputRef = React.createRef();
    this.otpRef = React.createRef();
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate = () => {
    const { error, isSubscribeUser } = this.props;

    if (error.errorMessage && isSubscribeUser) {
      this.handleBack();
    }
  }

  componentWillUnmount = () => {
    removeStorageData(VERIFY_SENT)
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (window.innerWidth < 768) {
      if (!this.inputRef?.current?.contains(event.target)) {
        this.handleBlur();
      }

      if (this.buttonRef?.current?.contains(event.target)) {
        this.handleSubmitForm();
      }
    }
  }
  handleResend = () => {
    this.props.onResend();
  }

  handleSubmitForm = (event) => {
    event?.preventDefault();

    const { handleSubmit } = this.props;
    const { otp } = this.state;

    if (otp) {
      this.setState({
        otp: null,
        otpError: null,
      });

      handleSubmit(otp);
    } else {
      this.setState({
        otpError: 'Field is required',
      });

      trackAmplitudeFailedEvent('invalid phone verification - signup', 'web app');
    }
  }

  handleChange = (event) => {
    this.setState({
      otp: event,
      otpError: null,
    })
  }

  handleBack = () => {
    this.props.handleBack();
  }

  handleFocus = () => {
    if (window.innerWidth < 753) {
      this.setState({
        submitButtonMobile: 'mobile',
      });
    }
  }

  handleBlur = () => {
    if (window.innerWidth < 753) {
      this.setState({
        submitButtonMobile: 'fixed',
      });
    }
  }

  handleRenderHeader = () => {
    const { template } = this.props;
    const isLoginPage = template === 'login';

    if (isLoginPage) {
      return (
        <div className="grid-x Verify__content--header">
          <button
            className="Verify__backButton"
            onClick={this.handleBack}
            type="button"
            aria-label="Back button"
          >
            <BackwardArrow />
          </button>
          <h1 className="Header__title Verify__title">
            Enter code
          </h1>
        </div>
      )
    }

    return (
      <div className="grid-x Verify__content--header">
        <button
          className="Verify__backButton Verify__backButton--small"
          onClick={this.handleBack}
          type="button"
          aria-label="Back button"
        >
          <BackwardArrow/>
        </button>
        <h1 className="Header__title Header__title--secondary-mob Verify__title">
          Enter code
        </h1>
      </div>
    )
  }

  render() {
    const { phone, error, isResend, verifyErrorMessage, isShowBar } = this.props;
    const { otp, otpError } = this.state;

    const errorMessage = verifyErrorMessage || error?.errorMessage || otpError;

    return (
      <Container
        isShowBar={isShowBar}
        classNameWrapper="u-height-full"
        isHideErrorModal
      >
        <section
          className="Main__container Main__container--secondary grid-x gxrid-padding-x grid-x align-center-middle">
          <div className="Verify__content medium-10 small-12 large-11">

            {this.handleRenderHeader()}

            <h2 className="Header__subtitle Verify__subtitle">
              We just texted {renderLocalNumber(phone)} a 6-digit code to verify your account.
            </h2>

            {
              errorMessage && (
                <div className="Verify__info text-error">
                <h5 className="h6 text-error">{errorMessage}</h5>
                </div>
              )
            }

            {
              isResend && !errorMessage && (
                <div className="Verify__info">
                  <h5 className="h6">A verification code has been sent!</h5>
                </div>
              )
            }

            <div className="Verify__otp--container u-form-container">
              <form onSubmit={this.handleSubmitForm} className="grid-y">
                <div
                  ref={this.inputRef}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  className="u-width-full"
                >
                  <ForwardedOtpInput
                    ref={this.otpRef}
                    value={otp}
                    onChange={this.handleChange}
                    numInputs={6}
                    inputType="number"
                    containerStyle="Verify__otp--wrap"
                    inputStyle="Verify__otp"
                    renderSeparator={<span className="Verify__otp--space"/>}
                    shouldAutoFocus
                    renderInput={(props, key) => <input {...props} autoFocus key={key} />}
                  />
                </div>
                <div className="Verify__footer u-links">
                  <span>Didn’t receive a 6-digit code?</span>
                  <button
                    className="Verify__footer-item u-link"
                    type="button"
                    onClick={this.handleResend}
                  >
                    Re-send code
                  </button>
                </div>
                <div
                  ref={this.buttonRef}
                  className={`Verify__cta FieldArea__submit--${this.state.submitButtonMobile}`}
                >
                  <Button text="Continue" type="submit" withArrow className="VerifyFrom__cta"/>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    verifyErrorMessage: state.verifyPage.verifyErrorMessage,
    isSubscribeUser: state.subscribePage.isSubscribeUser,
    error: state.error,
    isResend: state.verifyPage.isResend,
  };
}

export default connect(mapStateToProps)(React.memo(VerifyContent));
