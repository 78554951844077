import React, { Component } from 'react';
import ChooseAreaCode from './ChooseAreaCode';
import PropTypes from 'prop-types';

class InnerChooseAreaCode extends Component {
  render() {
    return (
      <ChooseAreaCode />
    );
  }
}
InnerChooseAreaCode.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default React.memo(InnerChooseAreaCode);
