import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cookie from 'react-cookie';

import * as actionCreators from '../ducks/dashboard';
import * as generalActions from '../ducks/general';

import { getCurrentUser, handleAuthCheck } from '../utils/user';
import AccountSummary from '../components/dashboard/AccountSummary';
import AppButtons from '../components/AppButtons';
import BurnersList from '../components/dashboard/BurnersList';
import CancelSubscriptionModal from '../components/modal/CancelSubscriptionModal';
import ReinstateSubscriptionModal from '../components/modal/ReinstateSubscriptionModal';
import SuccessModal from '../components/modal/SuccessModal';
import UpgradeBox from '../components/dashboard/UpgradeBox';
import Container from '../components/common/Container';
import {
  AUTHENTICATION_KEY,
  BURNER_IS_AUTH,
  CURRENT_USER,
  PAYWALL_CONFIG,
  ROUTERS,
  STORAGE_USER_STATUS,
} from '../constants';
import { handleLoadStripeV2 } from '../utils/stripe';
import { validateSubscriptions } from '../utils/subscription';

import '!style-loader!css-loader!sass-loader!../../styles/dashboard-rebrand.scss';
// import {defaultSessionOption} from '../utils/sessionStorage';

const propTypes = {
  authToken: PropTypes.string.isRequired,
  burners: PropTypes.arrayOf(
    PropTypes.shape({
      callerIdEnabled: PropTypes.bool,
      remainingTexts: PropTypes.number,
      name: PropTypes.string,
      expirationDate: PropTypes.number,
      phoneNumberId: PropTypes.string,
      hexColor: PropTypes.number,
      features: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          featureName: PropTypes.string,
        })
      ),
      expirationWarning: PropTypes.shape({
        id: PropTypes.number,
      }),
      alias: PropTypes.string,
      version: PropTypes.number,
      id: PropTypes.string,
      autoReplyActive: PropTypes.bool,
      dateCreated: PropTypes.number,
      lastUpdatedDate: PropTypes.number,
      renewalDate: PropTypes.number,
      ringer: PropTypes.bool,
      autoReplyType: PropTypes.number,
      remainingMinutes: PropTypes.number,
      productId: PropTypes.string,
      userId: PropTypes.string,
      autoReplyText: PropTypes.string,
      totalMinutes: PropTypes.number,
      notifications: PropTypes.bool,
      useSip: PropTypes.bool,
      totalTexts: PropTypes.number,
    })
  ),
  isAuthenticated: PropTypes.bool.isRequired,
  loadUserDetails: PropTypes.func.isRequired,
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      price: PropTypes.number,
      store: PropTypes.string,
      burnersAssignedInPeriod: PropTypes.number,
      id: PropTypes.string,
      receipt: PropTypes.string,
      renewalDate: PropTypes.number,
      creationDate: PropTypes.number,
      burnerIds: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  user: PropTypes.shape({
    trackingId: PropTypes.string,
    sip: PropTypes.shape({
      uri: PropTypes.string,
      password: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    totalNumberBurners: PropTypes.number,
    credits: PropTypes.number,
    version: PropTypes.number,
    id: PropTypes.string,
    dateCreated: PropTypes.number,
    carrierName: PropTypes.string,
    createdSample: PropTypes.bool,
    lastUpdatedDate: PropTypes.number,
    countryCode: PropTypes.string,
    superUser: PropTypes.bool,
    platform: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    lifetimeSubscriptions: PropTypes.number,
  }).isRequired,
};

const defaultProps = {
  burners: null,
  subscriptions: null,
};

class DashboardPage extends Component {
  componentDidMount() {
    const { loadUserDetails, clearUserInfo } = this.props;
    const user = getCurrentUser() || this.props.user;

    const { router } = this.context;
    const authToken = cookie.load(AUTHENTICATION_KEY);
    const isAuth = user?.isAuthenticated;
    const a = cookie.load(BURNER_IS_AUTH)

    if (user && a) {
      localStorage.removeItem(PAYWALL_CONFIG);
      loadUserDetails(authToken, user.id, ROUTERS.login);
    }

    if (user && !a) {
      cookie.remove(AUTHENTICATION_KEY);
      cookie.remove(CURRENT_USER);

      return router.push(ROUTERS.homePage);
    }

    if (!user || !getCurrentUser()?.id) {
      localStorage.removeItem(PAYWALL_CONFIG);

      router.push(isAuth ? ROUTERS.login : ROUTERS.homePage);
      clearUserInfo()
    }

    handleLoadStripeV2();
    this.handleLoadPage();

    document.addEventListener('visibilitychange', this.handleAuthenticateCheck);
  }

  componentDidUpdate(prevProps) {
    const { isLoading, isAuthenticated, user, subscriptions } = this.props;
    const { router } = this.context;

    if (subscriptions && validateSubscriptions(subscriptions)) {
      return router.push(ROUTERS.existAccount)
    }

    if (prevProps.isAuthenticated !== isAuthenticated && !isAuthenticated && !isLoading && !user) {
      return router.push(ROUTERS.login)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleAuthenticateCheck);
  }

  handleLoadPage = () => {
    const { router } = this.context;
    const user = getCurrentUser();

    /**
     * If the user is already registered but hasn't purchased a subscription,
     * allow skipping registration. And redirect user to /congrats page while registration;
     */

    if (user?.eligibleForPaywall) {
      sessionStorage.setItem(STORAGE_USER_STATUS, 'subscribe');
      cookie.save(BURNER_IS_AUTH, true);
      return router.push(ROUTERS.chooseNumber);
    }
  }

  handleAuthenticateCheck = () => {
    const { router } = this.context;
    const { userIsAuthentificate } = this.props;

    handleAuthCheck(router, userIsAuthentificate)
  }

  handleLogout = () => {
    this.props.logOutUser();
  }

  render() {
    const { user, burners, subscriptions } = this.props;

    return (
      <Container isShowFooter isShowDefaultHeader>
        <div className="Dashboard__container grid-x grid-padding-x align-center-middle">
          <AccountSummary subscriptions={subscriptions} user={user} handleLogout={this.handleLogout}/>
        </div>
        <div className="Dashboard__secondaryContainer Dashboard__container--bgWhite grid-x grid-padding-x align-center-middle">
          <div className="cell large-7 small-10">
            <h2 className="h3">Your phone numbers</h2>
            <div className="grid-x grid-padding-x">
              <div className="cell large-7">
                <p>
                  You have {burners ? burners.length : '0'} active phone{' '}
                  {burners && burners.length === 1 ? 'number' : 'numbers'}. Visit
                  the Burner app on mobile to create new or extend existing
                  numbers.
                </p>
              </div>
            </div>

            <div className="grid-x grid-padding-x align-left-middle">
              <BurnersList burners={burners} subscriptions={subscriptions} />
            </div>
          </div>

          <CancelSubscriptionModal />
          <ReinstateSubscriptionModal />
          <SuccessModal />
        </div>
        <div className="TileContainer">
          <UpgradeBox burners={burners} subscriptions={subscriptions} />
        </div>
        <div className="cell large-5">
          <AppButtons />
        </div>
      </Container>
    );
  }
}

DashboardPage.propTypes = propTypes;
DashboardPage.defaultProps = defaultProps;
DashboardPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authToken: state.auth.authToken,
    burners: state.dashboardPage.burners,
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.loading.isLoading,
    subscriptions: state.dashboardPage.subscriptions,
    user: state.auth.user,
  };
}

const mapDispatchToProps = { ...actionCreators, ...generalActions };

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
