import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  CHOOSE_NUMBER,
  CUSTOM_WEB_EVENT,
  ROUTERS, START_FLOW_SQUEEZE_WEB,
} from '../../constants';
import * as subscribeActions from '../../ducks/subscribe';
import * as progressBarActions from '../../ducks/progressBar';
import AvailableNumberList from '../../components/AvailableNumberList';
import {reselectAreaEvents, trackAmplitudeFailedEvent} from '../../utils/analytics';
import { clearStorage } from '../../utils/sessionStorage';
import {getCurrentUser} from '../../utils/user';

const propTypes = {
  areaCode: PropTypes.string.isRequired,
  maxResultsCount: PropTypes.number,
  numbers: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      phoneNumber: PropTypes.string,
      state: PropTypes.string,
      rateCenter: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  onChosenNumber: PropTypes.func.isRequired,
  requestNumber: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
};

const defaultProps = {
  maxResultsCount: null,
  numbers: null,
};

class NumberPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaCode: this.props.areaCode,
    };

    this.chooseAgain = this.chooseAgain.bind(this);
    this.searchAgain = this.searchAgain.bind(this);
    this.selectNumber = this.selectNumber.bind(this);
    this.handleRedirectPage = this.handleRedirectPage.bind(this);
  }

  componentDidMount() {
    const { currentStep, setCurrentStep } = this.props;

    if (currentStep === 1) {
      setCurrentStep(2);
    }

    clearStorage();

    $(':input[type=number]').on('mousewheel', () => {
      $(this).blur();
    });
  }

  chooseAgain() {
    const { resetSearch } = this.props;
    const { router } = this.context;

    reselectAreaEvents();

    resetSearch();
    router.push(ROUTERS.homePage);
  }

  searchAgain() {
    const { resetSearch } = this.props;
    const { router } = this.context;

    resetSearch();
    router.push(ROUTERS.homePage);

    dataLayer.push({
      event: CUSTOM_WEB_EVENT,
      customWebEventName: START_FLOW_SQUEEZE_WEB,
    })
  }

  selectNumber(number) {
    const { areaCode } = this.state;
    const { onChosenNumber, setChooseNumber } = this.props;

    dataLayer.push(
      {
        event: CUSTOM_WEB_EVENT,
        customWebEventName: CHOOSE_NUMBER,
        customWebEventAction: areaCode,
      },
    );
    setChooseNumber(number)
    onChosenNumber(number);

    this.handleRedirectPage();
  }

  handleRedirectPage() {
    const { router } = this.context;
    const user = getCurrentUser();

    /**
     * We use eligibleForPaywall, which comes in the registration response,
     * to check whether we can allow the user to proceed with payment creation or not
     */

    if (user && user.eligibleForPaywall) {
      router.push(ROUTERS.paywall);
    } else {
      router.push(ROUTERS.createAccount);
    }
  }
  render() {
    const { areaCode } = this.state;
    const { numbers, maxResultsCount } = this.props;

    if (!numbers) {
      return null;
    }

    if (numbers.length === 0) {
      trackAmplitudeFailedEvent('no numbers available for area code')

      return (
        <div className="PickNumber__noNumber">
          <h5 className="center">
            Sorry, there are no numbers available with that area code. Please{' '}
            <button
              className="Cta Cta--bold burner-link "
              type="button"
              onClick={this.searchAgain}
            >
              try a different one.
            </button>{' '}
          </h5>
        </div>
      );
    }

    return (
      <section>
        <AvailableNumberList
          chosenAreaCode={areaCode}
          numbers={numbers}
          maxResultsCount={maxResultsCount}
          onChosenNumber={this.selectNumber}
        />
        <div className="PickNumber__askNewNumber medium">
          Don’t see a number that you like? {' '}
          <button
            className="Cta burner-link PickNumber__link"
            onClick={this.chooseAgain}
            type="button"
          >
            Try another area code
          </button>
        </div>
      </section>
    );
  }
}

NumberPicker.propTypes = propTypes;
NumberPicker.defaultProps = defaultProps;
NumberPicker.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  maxResultsCount: state.chooseNumberPage.maxResultsCount,
  numbers: state.subscribePage.numbers,
  currentStep: state.progressBar.currentStep,
  user: state.auth.user,
});

const mapDispatchToProps = {...subscribeActions, ...progressBarActions };

export default connect(mapStateToProps, mapDispatchToProps)(NumberPicker);
