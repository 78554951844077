import axios from 'axios';
import cookie from 'react-cookie';
import {
  AUTHENTICATION_KEY,
  BASE_URL,
  CURRENT_USER,
  CUSTOM_WEB_EVENT,
  PURCHASE_ERROR,
  SELECTED_NUMBER, STORAGE_PAYMENT_STATUS,
  subscriptionType,
} from '../constants';
import { createBurner, handleFetchData } from './chooseNumber';
import { hasError } from './error';
import { requesting } from './general';
import { getCurrentUser } from '../utils/user';
import { extractError } from '../utils/request';
import { logPurchase, trackAmplitudeFailedEvent } from '../utils/analytics';
import { clearStorage, getStoragePlanData } from '../utils/sessionStorage';
import { createSubscription, setPaymentError } from './subscribe';
import { clearUserData } from './auth';
export const STRIPE_CLIENT_SECRET = 'burner-app/stripe-subscribe/STRIPE_CLIENT_SECRET';

export default function reducer(state = { sku: 'com.adhoclabs.burner.subscription.month.1' }, action) {
  switch (action.type) {
    case STRIPE_CLIENT_SECRET:
      return Object.assign({}, state, {
        sku: action.sku,
        clientSecretKey: action.clientSecretKey,
      });
    default:
      return state;
  }
}

export function setStripeClientSecret(sku, clientSecretKey) {
  return {
    type: STRIPE_CLIENT_SECRET,
    sku,
    clientSecretKey,
  }
}

/**
 * To process a payment, Stripe version 2.0 uses secure payment tokens
 */
export function getStripeAuthToken(payload) {
  return new Promise((resolve, reject) => {
    Stripe.card.createToken(payload, (statusCode, response) => {
      if (statusCode === 200) {
        resolve(response);
      } else {
        reject(response.error);
      }
    });
  });
}

export function handleStripeSubmit(formData) {
  return (dispatch) => {
    dispatch(requesting());

    const stripePayload = {
      name: `${formData.firstName} ${formData.lastName}`,
      number: formData.creditCard || formData.number,
      expMonth: formData.expMonth,
      expYear: formData.expYear,
      cvc: formData.cvc,
      address_zip: formData.zipCode,
    };

    return getStripeAuthToken(stripePayload)
      .then((response) => dispatch(createSubscription(formData, response)))
      .catch((error) => {
        trackAmplitudeFailedEvent(extractError(error), 'stripe');

        dispatch(hasError(extractError(error)))
      });
  };
}

/**
 * To process the payment, Stripe version 3.0 uses the Stripe embedded integration and client_secret,
 * which we receive in response to a request to the endpoint
 */
export function getStripeSession(sku) {
  const userId = getCurrentUser()?.id;
  const authToken = cookie.load(AUTHENTICATION_KEY);

  const body = {
    sku: sku || getStoragePlanData('sku'),
  };

  return (dispatch) => {
    dispatch(requesting());

    return axios
      .post(`${BASE_URL}/user/${userId}/stripe-sessions`,
        body,
        {
          headers: {
            Authentication: authToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        dispatch(setStripeClientSecret(sku, response.data.clientSecret));

        return response.data.clientSecret;
      })
      .catch((error) => {
        const isUnauth = error.status === 401;
        trackAmplitudeFailedEvent(extractError(error));

        if (isUnauth) {
          clearStorage();
          dispatch(clearUserData())
        }

        dispatch(hasError(
          isUnauth
            ? 'User is not authorized. Create a new Burner account or login to continue!'
            : extractError(error),
          error.status
        ))
      })
  };
}

export function setStripeSubscription(sku, sessionId, selectedNumber) {
  const userId = getCurrentUser()?.id;
  const authToken = cookie.load(AUTHENTICATION_KEY);
  const actualSelectedNumber = selectedNumber || localStorage.getItem(SELECTED_NUMBER);

  const body = {
    sessionId,
    sku: sku || getStoragePlanData('sku'),
  };

  return (dispatch) => {
    dispatch(requesting());
    dispatch(handleFetchData(true));

    return axios
      .post(`${BASE_URL}/user/${userId}/stripe-subscriptions`,
        body,
        {
          headers: {
            Authentication: authToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async (response) => {
        const subscription = getStoragePlanData();
        const paymentData = localStorage.getItem(STORAGE_PAYMENT_STATUS);
        const isSubscribeYearly = !!subscription.plan_sku_id.includes('year');
        const price = subscription.plan_price;
        const subscriptionDuration = isSubscribeYearly ? subscriptionType.yearly : subscriptionType.monthly;
        const subscriptionName = `${subscriptionDuration} ${price}`;

        await logPurchase(response.data.id, subscription.sku, price, subscriptionName);

        if (paymentData === 'clear') {
          localStorage.setItem(STORAGE_PAYMENT_STATUS, 'success');
        }

        dispatch(createBurner(
          authToken,
          userId,
          null,
          actualSelectedNumber,
          response.data.id,
          body.sku,
          null,
        ));
      })
      .catch((error) => {
        trackAmplitudeFailedEvent(extractError(error));

        if (error?.data?.error?.includes('not paid')) {
          dataLayer.push({
            event: CUSTOM_WEB_EVENT,
            customWebEventName: PURCHASE_ERROR,
            customWebEventLabel: extractError(error),
          });
          dispatch(setPaymentError('Ops. Something is wrong with your payment method. Try again'));
          dispatch(hasError('Ops. Something is wrong with your payment method. Try again', error.status));

          localStorage.setItem(STORAGE_PAYMENT_STATUS, 'failed');
        } else {
          cookie.remove(AUTHENTICATION_KEY);
          cookie.remove(CURRENT_USER);
          dispatch(clearUserData());
          dispatch(hasError(extractError(error), error.status))
        }
      })
  };
}
