import React, { Component } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DownloadBadge from '../components/confirmation/DownloadBadge';
import { QrCode } from '../components/confirmation/QrCode';
import {
  BURNER_CURRENT_STEP,
  BURNER_PAGE_STATE, ROUTER_SESSION_ID,
  ROUTERS, SELECTED_NUMBER, STORAGE_ONBOARDING_STATUS, STORAGE_PAYMENT_STATUS,
  USER_ELIGIBLE_FOR_PAYWALL, USER_PHONE_NUMBER,
} from '../constants';
import {getCurrentUser, userDeviceType} from '../utils/user';
import { getParamsByName, hideClientSecret } from '../utils/query';
import { renderLocalNumber } from '../utils/phone';
import Loader from '../components/Loader';

import * as subscribeActions from '../ducks/subscribe';
import * as generalActions from '../ducks/general';
import * as stripeSubscribeActions from '../ducks/stripe-subscribe';
import * as dashboardActions from '../ducks/dashboard';
import * as errorActions from '../ducks/error';

import '!style-loader!css-loader!sass-loader!../../styles/burner-details-rebrand.scss';
import { getStoragePlanData } from '../utils/sessionStorage';

class ConfirmationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user?.phoneNumber || sessionStorage.getItem(USER_PHONE_NUMBER),
      selectedNumber: localStorage.getItem(SELECTED_NUMBER),
      deviceType: null,
      firstLoad: true,
    };
  }

  componentDidMount() {
    localStorage.removeItem(BURNER_CURRENT_STEP);
    localStorage.removeItem(USER_ELIGIBLE_FOR_PAYWALL);
    localStorage.removeItem(BURNER_PAGE_STATE);

    this.initializePage();
  }

  componentDidUpdate(prevProps) {
    localStorage.removeItem(BURNER_CURRENT_STEP);

    const { paymentError, error, clearErrors } = this.props;
    if (paymentError && !prevProps.paymentError) {
      this.context.router.push(ROUTERS.paywall);
    }

    if (localStorage.getItem(STORAGE_PAYMENT_STATUS) === 'success') {
      localStorage.removeItem(SELECTED_NUMBER);
    }

    if (error?.errorMessage && error !== prevProps.error) {
      clearErrors();
    }
  }

  setDeviceTypeBasedOnScreenSize = () => {
    const userAgent = navigator.userAgent;

    if (!this.state.deviceType && window.innerWidth < 753) {
      this.setState({ deviceType: userDeviceType(userAgent) });
    }
  };

  handleRedirectUser = () => {
    const { router } = this.context;
    const { setStripeSubscription, selectedNumber } = this.props;
    const sessionId = getParamsByName(ROUTER_SESSION_ID);
    const sku = getStoragePlanData('sku');
    const statusPage = sessionStorage.getItem(STORAGE_ONBOARDING_STATUS);

    const user = getCurrentUser() || this.props.user;

    if (statusPage) {
      if (user?.isAuthenticated && !user?.eligibleForPaywall && !sessionId) {
        return this.context.router.push(ROUTERS.dashboard);
      }
      hideClientSecret();
    }

    if (!statusPage) {
      hideClientSecret();
      if (user?.isAuthenticated && !user?.eligibleForPaywall && !sessionId) {
        return this.context.router.push(ROUTERS.dashboard);
      }

      if (!sessionId || !this.state.user) {
        return router.push(ROUTERS.homePage);
      }

      setStripeSubscription(sku, sessionId, selectedNumber, false);
    }
  };

  initializePage = () => {
    this.handleRedirectUser();
    this.setDeviceTypeBasedOnScreenSize();
  };

  render() {
    const { deviceType, user } = this.state;
    const { paymentError, isFetching } = this.props;

    if (paymentError || isFetching) {
      return <Loader />;
    }

    return (
      <section className="Confirmation__container Main__container Main__container--secondary grid-y gxrid-padding-x">
        <div className="Confirmation__header">
          <h1 className="Header__title">All set!</h1>
          <h2 className="Header__subtitle">
            Follow these two steps to start using your new phone number
          </h2>
        </div>
        <Grid container className="Confirmation__columns" columns={24}>
          <Grid item xs={24} sm={8} lg={8} className="Confirmation__column Confirmation__column--first u-relative">
            <div className="Confirmation__column--number">1</div>
            <p className="Confirmation__column--text medium hide-for-small show-for-medium">
              Scan this QR code with your phone camera and then download the Burner app on your phone
            </p>
            <QrCode className="Confirmation__qrcode show-for-middle hide-for-small" />
            <p className="Confirmation__cta-text hide-for-medium show-for-small medium">
              Download the Burner app
            </p>
            <DownloadBadge className="Confirmation__apps vertical medium-horizontal" deviceType={deviceType} />
          </Grid>
          <Grid item xs={24} sm={8} lg={8} className="Confirmation__column u-relative">
            <div className="Confirmation__column--number medium">2</div>
            <p className="Confirmation__column--text medium">
              Sign in with your phone number: <span className="bold">{renderLocalNumber(user)}</span>
            </p>
          </Grid>
        </Grid>
      </section>
    );
  }
}

ConfirmationPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  selectedNumber: state.subscribePage.selectedNumber,
  user: state.auth.user,
  paymentError: state.subscribePage.paymentError,
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
  isFetching: state.chooseNumberPage.isFetching,
});

const mapDispatchToProps = {
  ...subscribeActions,
  ...generalActions,
  ...stripeSubscribeActions,
  ...dashboardActions,
  ...errorActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage);
